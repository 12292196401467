import { CloudUploadOutlined, Delete } from '@mui/icons-material';
import { Stack, Grid, Typography, TextField, Theme, useMediaQuery } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { Dayjs } from 'dayjs';
import { FileIcon, defaultStyles } from 'react-file-icon';

export interface SalesMarketingFileRowProps {
  id?: number;
  fileName: string;
  description?: string;
  fileUrl?: string;
  lastUpdated?: Dayjs;
  editMode: boolean;
  disabled?: boolean;
  error?: boolean;
  onDescriptionChanged?: (description: string) => void;
  onDelete?: (id: number | undefined) => void;
}

const SalesMarketingFileRow = (props: SalesMarketingFileRowProps) => {
  if (props.editMode) {
    return <SalesMarketingFileRowContent {...props} />;
  }

  return <SalesMarketingFileRowLinkWrapper {...props} />;
};

const SalesMarketingFileRowContent = (props: SalesMarketingFileRowProps) => {
  const labelWidth = '100px';
  const fileExtension = props.fileName?.split('.').pop();

  const smAndDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack m={0.5} mx={2}>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={3} alignItems="center" width="100%" my={0.5}>
            {!smAndDown && (
              <Stack height={50} width={40}>
                {fileExtension && (
                  //@ts-ignore
                  <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} />
                )}
                {!fileExtension && <CloudUploadOutlined fontSize="large" />}
              </Stack>
            )}
            <Stack width="100%">
              <Stack direction="row" alignItems="center">
                <Typography variant="body1" color="secondary" width={labelWidth}>
                  Filename:
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} color={props.error ? 'error' : 'inherit'}>
                  {props.fileName}
                </Typography>
              </Stack>
              {!props.editMode && (
                <Stack direction="row" alignItems="center" justifyItems="center">
                  <Typography variant="body2" color="secondary" width={labelWidth}>
                    Description:
                  </Typography>
                  <Typography variant="body2">{props.description}</Typography>
                </Stack>
              )}
              {props.editMode && (
                <Stack direction="row" alignItems="center" width="100%">
                  <Typography variant="body1" color="secondary" width={labelWidth}>
                    Description:
                  </Typography>
                  <Stack width="100%">
                    <TextField
                      autoComplete="off"
                      disabled={props.disabled}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={props.description ?? ''}
                      onChange={(event) => props.onDescriptionChanged?.(event.target.value)}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
            {props.editMode && (
              <Stack>
                <IconButton color="error" onClick={() => props.onDelete?.(props?.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

const SalesMarketingFileRowLinkWrapper = (props: SalesMarketingFileRowProps) => {
  return (
    <a href={props.fileUrl} download={props.fileUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
      <SalesMarketingFileRowContent {...props} />
    </a>
  );
};

export default SalesMarketingFileRow;
