import { Skeleton, Stack } from '@mui/material';

export interface TableLoadingSkeletonProps {
  rows: number;
}

const TableLoadingSkeleton = (props: TableLoadingSkeletonProps) => {
  return (
    <Stack direction="column" spacing={1.5}>
      {[...Array(props.rows)].map((i, index) => {
        return <Skeleton key={index} variant="rectangular" sx={{ bgcolor: 'grey.200' }} height={40} animation="wave" />;
      })}
    </Stack>
  );
};

export default TableLoadingSkeleton;
