import { Delete, DeleteOutline, FileCopyOutlined, Save } from '@mui/icons-material';
import {
  Button,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import ActionBar from 'components/action-bar/ActionBar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ApiResponse } from 'types/api';
import {
  MarketingMaterial,
  MarketingMaterialEditFormDto,
  MarketingMaterialExistingFileSaveDto,
  MarketingMaterialFilterOptions,
  MarketingMaterialNewFileSaveDto
} from 'types/pages/marketing';
import { DatePicker } from '@mui/x-date-pickers';
import axiosServices from 'utils/api/axiosServices';
import SalesMarketingFileRow from './SalesMarketingFileRow';
import dayjs from 'dayjs';
import useDashboardNavigate from 'hooks/useDashboardNavigate';
import Routes from 'routes/RouteNames';
import { FilterSelect } from 'components/filter-select/filter-select';
import MarketingDetailSkeleton from './marketing-details-skeleton';
import { dispatch } from 'store';
import { openAlert } from 'store/reducers/alert-dialog';

const useStyles = makeStyles((theme) => ({
  media: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
  }
}));

const hasDuplicateFiles = (savDto: MarketingMaterialEditFormDto): boolean => {
  if (!savDto) return false;

  const existingFiles = savDto.currentFiles?.map((f) => f.fileName) ?? [];
  const newFiles = savDto.newFiles?.map((f) => f.fileBlob?.name) ?? [];

  const allFiles = [...existingFiles, ...newFiles];

  const allFilesCount = existingFiles.length + newFiles.length;

  return new Set(allFiles).size !== allFilesCount;
};

const isDuplicateFilename = (filename: string, saveDto: MarketingMaterialEditFormDto): boolean => {
  if (!saveDto) return false;

  const existingFiles = saveDto.currentFiles?.filter((f) => f.fileName === filename).map((f) => f.fileName) ?? [];
  const newFiles = saveDto.newFiles?.filter((f) => f.fileBlob?.name === filename).map((f) => f.fileBlob?.name) ?? [];

  const matchingFiles = [...existingFiles, ...newFiles];

  return matchingFiles.length > 1;
};

const canSubmit = (details: MarketingMaterialEditFormDto) => {
  if (!details) return false;
  if (!details.title) return false;
  if (!details.description) return false;
  if (!details.currentFiles?.length && !details.newFiles?.length) return false;
  if (!details.newThumbnail?.fileBlob && !details.thumbnailUrl) return false;
  if (details.expiryDate && details.publishDate && details.expiryDate.isBefore(details.publishDate)) return false;
  if (hasDuplicateFiles(details)) return false;

  return true;
};

// TODO : Move to API layer
const getMarketingMaterial = async (id: string | null) => {
  if (!id) return null;

  const request: string = `marketing-materials/${id}`;
  const response = await axiosServices.get<ApiResponse<MarketingMaterial>>(request);

  return response.data.data;
};

const deleteMarketingMaterial = async (id: number) => {
  const request: string = `marketing-materials/${id}`;
  await axiosServices.delete<ApiResponse<MarketingMaterial>>(request);
};

const deleteMarketingFile = async (id: number) => {
  const request: string = `marketing-materials/files/${id}`;
  await axiosServices.delete<ApiResponse<MarketingMaterial>>(request);
};

const saveMarketingMaterial = async (saveDto: MarketingMaterialEditFormDto) => {
  if (!saveDto) return;

  var formData = new FormData();
  if (saveDto.id) {
    formData.append('id', saveDto.id?.toString());
  }
  formData.append('title', saveDto.title);
  formData.append('description', saveDto.description);
  formData.append('brand', saveDto.brand ?? '');
  formData.append('assetTypeId', saveDto.assetTypeId?.toString() ?? '1');
  formData.append('visibilityQuery', saveDto.visibilityQuery ?? '');
  formData.append('publishDate', saveDto.publishDate?.toISOString() ?? '');
  formData.append('expiryDate', saveDto.expiryDate?.toISOString() ?? '');

  for (let i = 0; i < saveDto.currentFiles.length; i++) {
    formData.append(`currentFiles[${i}].id`, saveDto.currentFiles[i].id?.toString() ?? '');
    formData.append(`currentFiles[${i}].description`, saveDto.currentFiles[i].description ?? '');
  }

  formData.append('newThumbnail.fileBlob', saveDto.newThumbnail?.fileBlob ?? '');

  for (let i = 0; i < saveDto.newFiles.length; i++) {
    formData.append(`newFiles[${i}].fileBlob`, saveDto.newFiles[i].fileBlob);
    formData.append(`newFiles[${i}].description`, saveDto.newFiles[i].description ?? '');
  }

  const request: string = `marketing-materials`;
  var response = await axiosServices.post<ApiResponse<MarketingMaterial>>(request, formData);

  return response.data.data;
};

const initialFormValues: MarketingMaterialEditFormDto = {
  title: '',
  description: '',
  brand: '',
  thumbnailUrl: null,
  assetTypeId: 1,
  assetTypeName: '',
  currentFiles: [],
  newFiles: []
};

interface ThumbnailPictureProps {
  thumbnail?: string | null;
  onThumbnailChange: (thumbnailUrl: string, file: File) => void;
  loading: boolean;
}

const ThumbnailPicture = (props: ThumbnailPictureProps) => {
  const hiddenInputRef = useRef();

  // @ts-ignore
  const handleUploadedFile = (event) => {
    const file = event.target.files[0];

    const urlImage = URL.createObjectURL(file);

    props.onThumbnailChange(urlImage, file);
  };

  const onUpload = () => {
    // @ts-ignore
    hiddenInputRef.current.click();
  };

  const uploadButtonLabel = props.thumbnail ? 'Change image' : 'Upload image';

  const error = props.thumbnail ? false : true;

  return (
    <MainCard content={false} border={!!props.thumbnail ? false : true}>
      <Stack alignItems="center" justifyContent="center" spacing={2} height={!!props.thumbnail ? undefined : '200px'}>
        <Input
          type="file"
          // @ts-ignore
          name="thumbnail"
          onChange={handleUploadedFile}
          inputRef={hiddenInputRef}
          style={{ display: 'none' }}
        />

        {!!props.thumbnail && (
          <CardMedia
            component="img"
            image={props.thumbnail ?? ''}
            title="Thumbnail"
            sx={{ objectFit: 'contain', minHeight: '150px', maxHeight: '100%', maxWidth: '100%' }}
          />
        )}

        <Button variant="dashed" color="info" onClick={onUpload} disabled={props.loading}>
          {uploadButtonLabel}
        </Button>
      </Stack>
      {error && (
        <Stack alignItems="center" p={1}>
          <Typography variant="body2" color="error">
            A thumbnail image is required
          </Typography>
        </Stack>
      )}
    </MainCard>
  );
};

const SalesMarketingEditDetailPage = () => {
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [options, setOptions] = useState<MarketingMaterialFilterOptions | null>(null);
  const [thumbnailImage, setThumbnailImage] = useState<string | null>(null);
  const [details, setDetails] = useState<MarketingMaterialEditFormDto>(initialFormValues);
  const [publishSwitch, setPublishSwitch] = useState<boolean>(false);
  const [expirySwitch, setExpirySwitch] = useState<boolean>(false);
  const hiddenFilesInput = useRef(null);
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useDashboardNavigate();

  const isCreate = !id;
  const noFiles = !details.currentFiles?.length && !details.newFiles?.length;
  const expiryBeforePublish = details.expiryDate && details.publishDate && details.expiryDate.isBefore(details.publishDate);

  const updateThumbnail = (thumbnail: string | null, file?: File) => {
    setThumbnailImage(thumbnail);

    if (file) {
      setDetails({ ...details, newThumbnail: { fileBlob: file } });
    }
  };

  const canSubmitForm = useMemo(() => canSubmit(details), [details]);

  const handleSubmit = useCallback(async () => {
    try {
      setSaving(true);
      const result = await saveMarketingMaterial(details);
      navigate(`${Routes.Marketing}/${result!.id}`, { replace: true });
    } finally {
      setSaving(false);
    }
  }, [details]);

  const handleDeleteMaterial = useCallback(
    async (id: number) => {
      dispatch(
        openAlert({
          open: true,
          size: 'xs',
          title: (
            <Stack direction="column" width="100%" justifyContent="center" alignItems="center" spacing={2}>
              <Delete color="error" sx={{ fontSize: '2rem' }} />
              <Typography variant="h6">Are you sure you want to delete this card?</Typography>
            </Stack>
          ),
          message: <></>,
          confirmButtonText: 'Delete',
          confirmButtonColour: 'error',
          cancelButtonText: 'Cancel',
          onConfirm: async () => {
            try {
              setSaving(true);
              await deleteMarketingMaterial(id);
              navigate(Routes.Marketing);
            } finally {
              setSaving(false);
            }
          }
        })
      );
    },
    [details]
  );

  const getFilterOptions = useCallback(async () => {
    try {
      setLoadingOptions(true);
      // TODO : Move to API Layer
      const response = await axiosServices.get<ApiResponse<MarketingMaterialFilterOptions>>('marketing-materials/filter-options');
      setOptions(response.data.data);
    } finally {
      setLoadingOptions(false);
    }
  }, []);

  const onAddFiles = () => {
    // @ts-ignore
    hiddenFilesInput.current.click();
  };

  // @ts-ignore
  const handleAddFiles = (event) => {
    const uploadedFiles = Array.from(event.target.files) as File[];

    const files = uploadedFiles.map((file) => ({
      file
    }));

    const filesToAdd: MarketingMaterialNewFileSaveDto[] = files.map(
      (file) =>
        ({
          fileBlob: file.file
        } as MarketingMaterialNewFileSaveDto)
    );

    setDetails({ ...details, newFiles: [...details.newFiles, ...filesToAdd] });

    // @ts-ignore
    hiddenFilesInput.current.value = '';
  };

  const loadSummary = useCallback(async () => {
    try {
      setLoading(true);

      const marketingMaterial = await getMarketingMaterial(id ?? null);

      if (!marketingMaterial) {
        return;
      }

      const editForm: MarketingMaterialEditFormDto = {
        id: marketingMaterial.id,
        title: marketingMaterial.title,
        description: marketingMaterial.description,
        thumbnailUrl: marketingMaterial.thumbnailUrl,
        brand: marketingMaterial.brand,
        assetTypeId: marketingMaterial.assetTypeId,
        assetTypeName: marketingMaterial.assetTypeName,
        currentFiles: marketingMaterial.files.map(
          (file) =>
            ({
              id: file.id,
              fileName: file.fileName,
              description: file.description
            } as MarketingMaterialExistingFileSaveDto)
        ),
        visibilityQuery: marketingMaterial.visibilityQuery,
        publishDate: marketingMaterial.publishDate ? dayjs(marketingMaterial.publishDate).startOf('day') : undefined,
        expiryDate: marketingMaterial.expiryDate ? dayjs(marketingMaterial.expiryDate).endOf('day') : undefined,
        newFiles: []
      };

      setDetails(editForm);
      updateThumbnail(editForm.thumbnailUrl);
      setPublishSwitch(!!editForm.publishDate);
      setExpirySwitch(!!editForm.expiryDate);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getFilterOptions();
    if (!isCreate) {
      loadSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableControls = loading || saving;

  return (
    <>
      {(loading || saving || loadingOptions) && <Loader />}
      <Grid container>
        <Grid item xs={12}>
          <ActionBar
            mainRegion={<></>}
            primaryAction={
              <>
                {!loading && (
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="dashed"
                      color="success"
                      disabled={!canSubmitForm || disableControls}
                      startIcon={<Save />}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                    {!isCreate && (
                      <Button
                        variant="dashed"
                        color="error"
                        disabled={disableControls}
                        startIcon={<DeleteOutline />}
                        onClick={async () => handleDeleteMaterial(details.id!)}
                      >
                        Delete
                      </Button>
                    )}
                  </Stack>
                )}
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <form>
            <MainCard>
              {loading && <MarketingDetailSkeleton />}
              {!loading && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <MainCard className={classes.media}>
                      <ThumbnailPicture
                        thumbnail={thumbnailImage}
                        onThumbnailChange={(thumbnail, file) => updateThumbnail(thumbnail, file)}
                        loading={saving}
                      />
                    </MainCard>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Stack spacing={1.5}>
                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        error={!details.title}
                        disabled={disableControls}
                        helperText={!details.title ? 'Title is required' : null}
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        label="Title"
                        value={details.title ?? ''}
                        onChange={(event) => setDetails({ ...details, title: event.target.value })}
                      />
                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled={disableControls}
                        multiline
                        rows={3}
                        label="Description"
                        value={details.description ?? ''}
                        error={!details.description}
                        helperText={!details.description ? 'Description is required' : null}
                        onChange={(event) => setDetails({ ...details, description: event.target.value })}
                      />
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{ minWidth: 200 }}>
                          <InputLabel>{!details.assetTypeId ? 'Select a type...' : 'Type'}</InputLabel>
                          <FilterSelect
                            disabled={disableControls}
                            label="Status"
                            hideClearOption
                            options={options?.assetTypes ?? []}
                            selected={options?.assetTypes?.find((p) => p.value.toString() === details.assetTypeId.toString()) ?? null}
                            onSelected={(option) => setDetails({ ...details, assetTypeId: Number(option?.value) ?? 1 })}
                          />
                        </FormControl>

                        <FormControl sx={{ minWidth: 200 }}>
                          <InputLabel>{!details.brand ? null : 'Brand'}</InputLabel>
                          <FilterSelect
                            disabled={disableControls}
                            label="Select a brand..."
                            options={options?.brands ?? []}
                            selected={options?.brands?.find((p) => p.value?.toString() === details.brand?.toString()) ?? null}
                            onSelected={(option) => setDetails({ ...details, brand: option?.value ?? null })}
                          />
                        </FormControl>
                      </Stack>
                      <Stack>
                        <TextField
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled={disableControls}
                          multiline
                          label={!!details.visibilityQuery ? 'Visibility Query' : 'Enter Visibility Query (Optional)'}
                          value={details.visibilityQuery ?? ''}
                          onChange={(event) => setDetails({ ...details, visibilityQuery: event.target.value })}
                        />
                      </Stack>

                      <Stack>
                        <Stack>
                          <Stack direction="row" alignItems="center">
                            <FormControlLabel
                              sx={{ width: '200px' }}
                              control={
                                <Switch
                                  disabled={disableControls}
                                  checked={publishSwitch}
                                  onChange={(e) => {
                                    setPublishSwitch(e.target.checked);

                                    if (!e.target.checked) {
                                      setDetails({ ...details, publishDate: undefined });
                                    }
                                  }}
                                />
                              }
                              label="Publish After"
                            />
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              value={details.publishDate}
                              disabled={!publishSwitch}
                              onChange={(value) => setDetails({ ...details, publishDate: value ?? undefined })}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  disabled={disableControls}
                                  size="small"
                                  onKeyDown={(e) => e.preventDefault()}
                                  {...params}
                                />
                              )}
                            />
                          </Stack>
                          <Typography variant="body2">
                            Add a publish date to make the marketing card visible to customers only after the specified date has passed.
                          </Typography>
                        </Stack>

                        <Stack>
                          <Stack direction="row" alignItems="center">
                            <FormControlLabel
                              sx={{ width: '200px' }}
                              control={
                                <Switch
                                  disabled={disableControls}
                                  checked={expirySwitch}
                                  onChange={(e) => {
                                    setExpirySwitch(e.target.checked);

                                    if (!e.target.checked) {
                                      setDetails({ ...details, expiryDate: undefined });
                                    }
                                  }}
                                />
                              }
                              label="Expire After"
                            />
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              value={details.expiryDate}
                              disabled={!expirySwitch}
                              onChange={(value) => setDetails({ ...details, expiryDate: value ?? undefined })}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  disabled={disableControls}
                                  size="small"
                                  onKeyDown={(e) => e.preventDefault()}
                                  {...params}
                                />
                              )}
                            />
                          </Stack>
                          <Typography variant="body2" color={expiryBeforePublish ? 'error' : undefined}>
                            {expiryBeforePublish
                              ? 'The expiry date cannot be before the publish date.'
                              : 'Add an expiry date to make the marketing card expire after the specified date.'}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MainCard
                      content={false}
                      title={
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Stack>
                            <Typography variant="h5">Files</Typography>
                            <Typography variant="body2" color="secondary">
                              The list of files to be attached to the marketing card.
                            </Typography>
                          </Stack>
                          <Button
                            disabled={disableControls}
                            variant="dashed"
                            color="info"
                            startIcon={<FileCopyOutlined />}
                            onClick={onAddFiles}
                          >
                            Add File(s)
                          </Button>
                        </Stack>
                      }
                    >
                      <input ref={hiddenFilesInput} multiple type="file" style={{ display: 'none' }} onChange={handleAddFiles} />
                      <Stack>
                        {noFiles && (
                          <Stack alignItems="center" m={2}>
                            <Typography color="error">No files are attached. At least one file is required to create the card.</Typography>
                          </Stack>
                        )}

                        {details.currentFiles.map((file, index) => {
                          return (
                            <>
                              <SalesMarketingFileRow
                                key={index}
                                id={file.id}
                                editMode={true}
                                disabled={disableControls}
                                fileName={file.fileName}
                                description={file.description}
                                onDelete={async () => {
                                  setDetails({ ...details, currentFiles: [...details.currentFiles.filter((f) => f !== file)] });
                                  if (file.id) {
                                    await deleteMarketingFile(file.id);
                                  }
                                }}
                                onDescriptionChanged={(description) => {
                                  const file = details.currentFiles[index];

                                  file.description = description;

                                  setDetails({
                                    ...details,
                                    currentFiles: [...details.currentFiles]
                                  });
                                }}
                              />
                              <Divider />
                            </>
                          );
                        })}
                        {details.newFiles?.map((file, index) => {
                          const isDuplicate = isDuplicateFilename(file.fileBlob.name, details);

                          return (
                            <>
                              <SalesMarketingFileRow
                                key={index}
                                editMode={true}
                                fileName={file.fileBlob?.name ?? ''}
                                disabled={disableControls}
                                description={file.description}
                                error={isDuplicate}
                                onDelete={() => {
                                  setDetails({ ...details, newFiles: [...details.newFiles.filter((f) => f !== file)] });
                                }}
                                onDescriptionChanged={(description) => {
                                  const file = details.newFiles[index];

                                  file.description = description;

                                  setDetails({
                                    ...details,
                                    newFiles: [...details.newFiles]
                                  });
                                }}
                              />
                              {isDuplicate && (
                                <Stack mx={1} mb={1}>
                                  <Typography variant="subtitle1" color="error">
                                    A file with this name already exists. Remove and rename the file before saving.
                                  </Typography>
                                </Stack>
                              )}
                              <Divider />
                            </>
                          );
                        })}
                      </Stack>
                    </MainCard>
                  </Grid>
                </Grid>
              )}
            </MainCard>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default SalesMarketingEditDetailPage;
