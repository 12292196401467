import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material';
import { Stack, useTheme, Grid, Typography, MenuItem, Select, FormControl, TextField, Pagination, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { PaginationState, SortDirection } from 'types/data-tables';

interface HeaderSortProps {
  column: any;
  sort?: boolean;
}

export const HeaderSort = ({ column, sort }: HeaderSortProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ display: 'inline-flex' }}>
      <Stack direction="column">
        {column.render('Header')}
        {column.SubHeader && (
          <Typography variant="body2" color="secondary">
            {column.SubHeader}
          </Typography>
        )}
      </Stack>
      {!column.disableSortBy && (
        <Stack sx={{ color: 'secondary.light' }} {...(sort && { ...column.getHeaderProps(column.getSortByToggleProps()) })}>
          <ArrowDropUpOutlined
            style={{
              fontSize: '0.625rem',
              color: column.sortDirection === SortDirection.ASC ? theme.palette.text.secondary : 'inherit'
            }}
          />
          <ArrowDropDownOutlined
            style={{
              fontSize: '0.625rem',
              marginTop: -2,
              color: column.sortDirection === SortDirection.DESC ? theme.palette.text.secondary : 'inherit'
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

interface TablePaginationProps {
  pagination: PaginationState;
  disabled?: boolean;
  onPaginationChanged: (pagination: PaginationState) => void;
}

export const TablePagination: React.FC<TablePaginationProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    const pagination: PaginationState = {
      ...props.pagination,
      page: value
    };

    props.onPaginationChanged(pagination);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    const pagination: PaginationState = {
      ...props.pagination,
      pageSize: event.target.value as number
    };

    props.onPaginationChanged(pagination);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}>
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="secondary">
              Row per page
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                // @ts-ignore
                value={props.pagination.pageSize}
                onChange={handleChange}
                size="small"
                sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="caption" color="secondary">
            Go to
          </Typography>
          <TextField
            autoComplete="off"
            size="small"
            type="number"
            // @ts-ignore
            value={props.pagination.page}
            onChange={(e) => {}}
            sx={{ '& .MuiOutlinedInput-input': { py: 0.75, px: 1.25, width: 36 } }}
          />
        </Stack>
      </Grid>
      <Grid item>
        {props.pagination.totalCount && (
          <Typography variant="caption" color="secondary">
            {(props.pagination.page - 1) * props.pagination.pageSize + 1}-
            {Math.min(props.pagination.page * props.pagination.pageSize, props.pagination.totalCount)} of {props.pagination.totalCount}{' '}
            items
          </Typography>
        )}
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          disabled={props.disabled}
          // @ts-ignore
          count={Math.ceil(props.pagination.totalPages)}
          // @ts-ignore
          page={props.pagination.page}
          onChange={handleChangePagination}
          color="standard"
          variant="contained"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};
