import { Button, Popover, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BackNavigation from 'components/BackNavigation';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';

export interface ActionBarProps {
  mainRegion?: React.ReactElement | React.ReactNode;
  primaryAction?: React.ReactElement | React.ReactNode;
  moreActions?: React.ReactElement | React.ReactNode;
  hideBackNavigation?: boolean;
}

const ActionBar = (props: ActionBarProps) => {
  const theme = useTheme();
  const matchLgAndDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState<HTMLElement | null>(null);
  const hasActions = !!props.primaryAction || !!props.moreActions;

  const handlePopoverClose = () => {
    setMoreActionsAnchorEl(null);
    setMoreActionsOpen(false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreActionsAnchorEl(event.currentTarget);
    setMoreActionsOpen(true);
  };

  return (
    <Stack direction="row" height="40px" mb={1} alignItems="center" justifyContent="space-between" spacing={1}>
      <Stack direction="row">
        {!props.hideBackNavigation && (
          <Stack mr={2} height="100%" direction="row" alignItems="center">
            <BackNavigation style={matchLgAndDown ? 'icon' : 'button'} size="medium" />
          </Stack>
        )}
        {props.mainRegion}
      </Stack>

      {hasActions && (
        <>
          <Stack height="100%" direction="row" alignItems="center" justifyContent="end" spacing={matchLgAndDown ? 1 : 1}>
            {!!props.primaryAction && props.primaryAction}

            {!!props.moreActions && (
              <Button
                endIcon={<ArrowDropDownIcon fontSize="large" color="secondary" />}
                variant="text"
                size="small"
                color="inherit"
                onClick={handlePopoverOpen}
              >
                <Typography variant="subtitle1" color="secondary">
                  {matchLgAndDown ? 'Actions' : 'More Actions'}
                </Typography>
              </Button>
            )}
          </Stack>

          <Popover
            id="more actions"
            open={moreActionsOpen}
            anchorEl={moreActionsAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            {props.moreActions}
          </Popover>
        </>
      )}
    </Stack>
  );
};

export default ActionBar;
