import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState, dispatch } from 'store';
import { Address, ApiResponse, PagedList } from 'types/api';
import {
  DraftProductDto,
  SalesOrderDraftDto,
  SalesOrderLine,
  SaveSalesOrderDraftDto,
  StockGrade,
  mapToSaveDraft,
  mapToSaveDraftProduct
} from 'types/pages/sales-orders';
import axiosServices from 'utils/api/axiosServices';
import { ProductStockDto } from 'types/pages/products';
import dayjs from 'dayjs';
import { setLocalStorage } from 'utils/localStorage';
import LocalStorageKeys from 'localStorageKeys';
import { isEmptyOrSpaces } from 'utils/stringUtils';

export interface PinnedOrderProps {
  pinnedOrder: SalesOrderDraftDto | null;
  isFetchingPinnedOrder: boolean;
  isUpdatingPinnedOrder: boolean;
}

const defaultAddress: Address = {
  contact: '',
  customerCode: '',
  companyName: '',
  address: '',
  address1: '',
  address2: '',
  townOrCity: '',
  county: '',
  postcode: '',
  country: 'United Kingdom',
  countryCode: 'GB',
  phoneNumber: '',
  exportCode: '',
  emailAddress: '',
  emailCcAddress: '',
  vatNumber: '',
  vatTwoDigits: '',
  forInvoice: false,
  forDelivery: false,
  forStatement: false
};

export const PinnedOrderInitialState: PinnedOrderProps = {
  pinnedOrder: {
    id: null,
    customerRef: '',
    customerRef2: '',
    customerRef3: '',
    despatchOn: dayjs().endOf('day').toDate(),
    deliveryAddress: defaultAddress,
    invoiceAddress: defaultAddress,
    deliveryOptionKey: '',
    fullShip: true,
    deliveryNotes: '',
    warehouseNotes: '',
    whiteLabelled: false,
    vatCode: '',
    vatDescription: '',
    products: []
  },
  isFetchingPinnedOrder: false,
  isUpdatingPinnedOrder: false
};

const slice = createSlice({
  name: 'pinnedOrder',
  initialState: { ...PinnedOrderInitialState },
  reducers: {
    setSavingPinnedOrder(state, action: PayloadAction<boolean>) {
      state.isUpdatingPinnedOrder = action.payload;
    },
    setPinnedOrder(state, action: PayloadAction<SalesOrderDraftDto | null>) {
      state.pinnedOrder = action.payload;
    },
    setFetchingPinnerOrder(state, action: PayloadAction<boolean>) {
      state.isFetchingPinnedOrder = action.payload;
    },
    resetActiveDraft: () => PinnedOrderInitialState
  }
});

export default slice.reducer;
export const { setPinnedOrder, setFetchingPinnerOrder, setSavingPinnedOrder } = slice.actions;

export interface AddProductToOrder {
  itemToAdd: ProductStockDto;
  quantity: number;
}

export function createPinnedReOrder(lines: SalesOrderLine[]) {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(slice.actions.setSavingPinnedOrder(true));
      dispatch(assignPinnedOrder(null));

      const skusFilter = lines
        .filter((line) => !isEmptyOrSpaces(line.stockCode))
        .map((line) => line.stockCode)
        .join(',');

      const request: string = `products?skus=${skusFilter}&pageSize=100`;

      const response = await axiosServices.get<ApiResponse<PagedList<ProductStockDto>>>(request);

      const products = response.data.data.items;

      let itemsToAdd: AddProductToOrder[] = [];

      for (let index = 0; index < products.length; index++) {
        const matchingLine = lines.find((line) => line.stockCode === products[index].product.sku);

        itemsToAdd.push({ itemToAdd: products[index], quantity: matchingLine?.quantity ?? 1 });
      }

      const draft = await dispatch(addOrUpdatePinnedOrderProducts(itemsToAdd, true));

      return draft.id;
    } finally {
      dispatch(slice.actions.setSavingPinnedOrder(false));
    }
  };
}

export function addOrUpdatePinnedOrderProducts(products: AddProductToOrder[], save: boolean = true) {
  return async (dispatch: any, getState: () => RootState) => {
    const accountInContext = getState().accountInContext;

    let draft: SalesOrderDraftDto = getState().pinnedOrder.pinnedOrder ?? PinnedOrderInitialState.pinnedOrder!;

    let saveDto: SaveSalesOrderDraftDto = mapToSaveDraft(accountInContext.accountRole?.account.accountCode ?? '', draft);

    // Build SaveDto
    for (let index = 0; index < products.length; index++) {
      const product = products[index];

      if (!product) {
        continue;
      }

      let existingProduct = saveDto.lines.find((p) => p.stockCode === product.itemToAdd.product.sku);

      if (!existingProduct) {
        const lineToAdd: DraftProductDto = {
          productDetails: product.itemToAdd,
          quantity: product.quantity,
          totalPrice: (product.itemToAdd.priceWhenNew ?? 0) * product.quantity,
          currencySymbol: product.itemToAdd.currencySymbol,
          grade: StockGrade.New
        };

        saveDto.lines.push(mapToSaveDraftProduct(lineToAdd));
      } else {
        existingProduct.quantity += product.quantity;
      }
    }

    if (save && saveDto !== null) {
      return await dispatch(savePinnedOrder(saveDto, true));
    }

    return draft;
  };
}

export function deletePinnedOrderDraft(draftId: number) {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setSavingPinnedOrder(true));

      await axiosServices.delete(`sales-orders/drafts/${draftId}`);

      dispatch(slice.actions.setPinnedOrder(null));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSavingPinnedOrder(false));
    }
  };
}

export function fetchPinnedOrder(draftId: number) {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setFetchingPinnerOrder(true));
      dispatch(assignPinnedOrder(null));

      const response = await axiosServices.get<ApiResponse<SalesOrderDraftDto>>(`sales-orders/drafts/${draftId}`);
      const draft = response.data.data;

      dispatch(assignPinnedOrder(draft));

      return response.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetchingPinnerOrder(false));
    }
  };
}

export function assignPinnedOrder(draft: SalesOrderDraftDto | null) {
  const twentyFourHoursTtl = 1000 * 60 * 60 * 24;

  return (dispatch: any) => {
    if (draft) {
      setLocalStorage<number>(LocalStorageKeys.ActiveDraftId, JSON.stringify(draft?.id), twentyFourHoursTtl);
    }

    dispatch(slice.actions.setPinnedOrder(draft));
  };
}

export function savePinnedOrder(saveDto: SaveSalesOrderDraftDto, resetDeliveryOption: boolean = false) {
  return async () => {
    try {
      dispatch(slice.actions.setSavingPinnedOrder(true));

      if (resetDeliveryOption) {
        saveDto.deliveryOptionKey = '';
      }

      const response = await axiosServices.put<ApiResponse<SalesOrderDraftDto>>('/sales-orders/drafts', saveDto);

      const draft = response.data.data;

      dispatch(assignPinnedOrder(draft));

      return draft;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSavingPinnedOrder(false));
    }
  };
}
