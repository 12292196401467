import { Box, Button, Checkbox, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { PaginationState, SortDirection } from 'types/data-tables';
import { HeaderSort, TablePagination } from './ReactTable';
import TableLoadingSkeleton from 'components/table-loading-skeleton/TableLoadingSkeleton';
import React, { useEffect, useMemo } from 'react';

// TODO : Propertly type / generic this
export interface DashboardTableProps {
  rowKey?: string;
  columns: Column<any>[];
  rows: any[];
  isFetching: boolean;
  pagination?: PaginationState;
  loadingText?: string;
  title?: string;
  noResultsRegion?: React.ReactNode | string | null;
  hideHeaders?: true;
  hideColumns?: string[];
  selectable?: boolean;
  selectedRows?: any[];
  onPaginationChanged?: (pagination: PaginationState) => void;
  onRowClicked?: (row: any) => void;
  onHeaderClicked?: (column: any, sortDirection: SortDirection) => void;
  onReset?: () => void;
  onSelectedRowsChanged?: (rows: any[]) => void;
}

export const DashboardTable = (props: DashboardTableProps) => {
  const selectedColumnDefinition = useMemo(() => {
    return {
      id: 'select',
      Header: 'Select',
      // @ts-ignore
      sortable: false,
      // @ts-ignore
      disableSortBy: true,
      // @ts-ignore
      Cell: ({ row }) => {
        return (
          <Stack direction="row" alignItems="center" justifyContent="center" width="100%" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              checked={props.selectedRows?.includes(row.original)}
              onChange={(e) => {
                if (e.target.checked) {
                  props.onSelectedRowsChanged?.([...(props.selectedRows ?? []), row.original]);
                } else {
                  props.onSelectedRowsChanged?.(props.selectedRows?.filter((r) => r !== row.original) ?? []);
                }
              }}
            />
          </Stack>
        );
      }
    };
  }, [props.selectedRows]);

  const columns = useMemo(
    () => (!props.selectable ? props.columns : [selectedColumnDefinition, ...props.columns]),
    [props.columns, props.selectable, selectedColumnDefinition]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    allColumns
  } = useTable(
    {
      // @ts-ignore
      columns: columns,
      data: props.rows,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: 15, pageCount: 1, hiddenColumns: props.hideColumns ?? [] },
      autoResetHiddenColumns: true,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      getRowId: React.useCallback((row, index) => (props.rowKey ? row[props.rowKey] : index), [props.rowKey])
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (!props.hideColumns || props.columns.length === 0) return;
    if (!allColumns || allColumns.length === 0) return;

    allColumns.forEach((c) => {
      if (c.id) {
        const isHidden = props.hideColumns?.some((hidden) => c.id === hidden);

        c.toggleHidden(isHidden);
      }
    });
  }, [props.hideColumns, props.columns.length, allColumns]);

  const getSortByHeaderProps = (column: any) => column.getSortByToggleProps();

  const mapHeaderSorting = (column: any): SortDirection => {
    switch (column.sortDirection) {
      case SortDirection.None:
        return SortDirection.ASC;
      case SortDirection.ASC:
        return SortDirection.DESC;
      case SortDirection.DESC:
        return SortDirection.None;
    }

    return SortDirection.None;
  };

  return (
    <>
      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 1 }}>
          {props.title && (
            <TableRow>
              <TableCell colSpan={props.columns.length}>{props.title}</TableCell>
            </TableRow>
          )}
          {!props.hideHeaders &&
            headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps([{ className: column.className }, getSortByHeaderProps(column)])}
                    onClick={() => {
                      if (props.onHeaderClicked) props.onHeaderClicked(column, mapHeaderSorting(column));
                    }}
                  >
                    <HeaderSort column={column} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {props.isFetching && (
            <TableRow>
              <TableCell colSpan={props.selectable ? 12 : 11}>
                <TableLoadingSkeleton rows={props.pagination?.pageSize ?? 5} />
              </TableCell>
            </TableRow>
          )}

          {!props.isFetching && props.rows.length === 0 && !props.noResultsRegion && (
            <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={11} component="th" scope="row">
                <Stack width="100%" direction="column" alignItems="center" justifyContent="center" my={10}>
                  <Typography variant="h6">No results matching the search criteria.</Typography>
                  <Typography variant="body1">Hit the clear button to reset any active filters.</Typography>
                  <Button
                    size="large"
                    sx={{ mt: { xs: 2 } }}
                    variant="contained"
                    onClick={() => {
                      if (props.onReset) {
                        props.onReset();
                      }
                    }}
                  >
                    Clear
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          )}

          {!props.isFetching && props.rows.length === 0 && props.noResultsRegion && (
            <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={11} component="th" scope="row">
                {props.noResultsRegion}
              </TableCell>
            </TableRow>
          )}

          {!props.isFetching &&
            page.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => {
                    // Prevent onRowClicked from firing when text is selected, i.e. allow copy & paste
                    if (window.getSelection()?.toString().length !== 0) return;

                    if (props.onRowClicked) props.onRowClicked(row.original);
                  }}
                >
                  {row.cells.map((cell: any) => (
                    <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                  ))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {props.pagination && (
        <Box sx={{ p: 2 }}>
          <TablePagination
            disabled={props.isFetching}
            pagination={props.pagination}
            onPaginationChanged={(prop) => {
              if (props.onPaginationChanged) props.onPaginationChanged(prop);
            }}
          />
        </Box>
      )}
    </>
  );
};
