import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState, dispatch } from 'store';
import { AccountSummaryDto, Address, ApiResponse, Country, ValidatedResult, ValidationFailure } from 'types/api';
import {
  DeliveryOption,
  SalesOrderDraftDto,
  SaveSalesOrderDraftDto,
  mapToSaveDraft as mapToSaveDraftDto,
  SalesOrderDraftTotalsDto,
  SalesOrderHeader,
  SalesOrderCreateOptionsDto
} from 'types/pages/sales-orders';
import axiosServices from 'utils/api/axiosServices';
import { openSnackbar } from '../snackbar';
import { assignPinnedOrder, setFetchingPinnerOrder } from '../pinned-order';
import { clearLocalStorage } from 'utils/localStorage';
import LocalStorageKeys from 'localStorageKeys';

export interface CreateSalesOrderProps {
  isSavingDraft: boolean;
  isValidating: boolean;
  isPlacingOrder: boolean;
  validatingAbortController: AbortController | null;
  apiValidationErrors: ValidationFailure[];
  deliveryOptions: DeliveryOption[] | null;
  isFetchingDeliveryOptions: boolean;
  deliveryOptionsAbortController: AbortController | null;
  countryOptions: Country[] | null;
  defaultInvoiceAddress: Address | null;
  loading: boolean;
  accountSummary: AccountSummaryDto | null;
  isFetchingTotals: boolean;
  totals: SalesOrderDraftTotalsDto;
  totalsAbortController: AbortController | null;
  confirmDialogOpen: boolean;
}

export const CreateSalesOrderInitialState: CreateSalesOrderProps = {
  isSavingDraft: false,
  isValidating: false,
  isPlacingOrder: false,
  apiValidationErrors: [],
  validatingAbortController: null,
  deliveryOptions: null,
  isFetchingDeliveryOptions: false,
  deliveryOptionsAbortController: null,
  countryOptions: null,
  defaultInvoiceAddress: null,
  loading: false,
  accountSummary: null,
  isFetchingTotals: false,
  totals: {
    subTotal: 0,
    tax: 0,
    total: 0,
    taxDescription: '',
    currencySymbol: '£',
    isPOA: false,
    taxRate: 0,
    maxDimensionInCm: 0,
    approximateWeightInKg: 0
  },
  totalsAbortController: null,
  confirmDialogOpen: false
};

const slice = createSlice({
  name: 'createSalesOrder',
  initialState: { ...CreateSalesOrderInitialState },
  reducers: {
    setIsSavingDraft(state, action: PayloadAction<boolean>) {
      state.isSavingDraft = action.payload;
    },
    setIsValidating(state, action: PayloadAction<{ isValidating: boolean; abortController?: AbortController }>) {
      state.isValidating = action.payload.isValidating;
      state.validatingAbortController = action.payload.abortController ?? null;
    },
    setIsPlacingOrder(state, action: PayloadAction<boolean>) {
      state.isPlacingOrder = action.payload;
    },
    setApiValidationErrors(state, action: PayloadAction<ValidationFailure[]>) {
      state.apiValidationErrors = action.payload;
    },
    setDeliveryOptions(state, action: PayloadAction<DeliveryOption[] | null>) {
      state.deliveryOptions = action.payload;
    },
    setIsFetchingDeliveryOptions(state, action: PayloadAction<{ isFetching: boolean; abortController?: AbortController }>) {
      state.isFetchingDeliveryOptions = action.payload.isFetching;
      state.deliveryOptionsAbortController = action.payload.abortController ?? null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCountryOptions(state, action: PayloadAction<Country[] | null>) {
      state.countryOptions = action.payload;
    },
    setDefaultInvoiceAddress(state, action: PayloadAction<Address | null>) {
      state.defaultInvoiceAddress = action.payload;
    },
    setAccountSummary(state, action: PayloadAction<AccountSummaryDto | null>) {
      state.accountSummary = action.payload;
    },
    setTotals(state, action: PayloadAction<SalesOrderDraftTotalsDto>) {
      state.totals = action.payload;
    },
    setFetchingTotals(state, action: PayloadAction<{ isFetching: boolean; abortController?: AbortController }>) {
      state.isFetchingTotals = action.payload.isFetching;
      state.totalsAbortController = action.payload.abortController ?? null;
    },
    setConfirmDialogOpen(state, action: PayloadAction<boolean>) {
      state.confirmDialogOpen = action.payload;
    },
    resetCreateSalesOrder: () => CreateSalesOrderInitialState
  }
});

export default slice.reducer;
export const { setIsSavingDraft, setDeliveryOptions, resetCreateSalesOrder, setConfirmDialogOpen } = slice.actions;

export function loadForm(draftId?: number) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const optionsResponse = await axiosServices.get<ApiResponse<SalesOrderCreateOptionsDto>>('/sales-orders/create-options');

      dispatch(slice.actions.setCountryOptions(optionsResponse.data.data.countries));
      dispatch(slice.actions.setDefaultInvoiceAddress(optionsResponse.data.data.defaultInvoiceAddress));

      const accountSummaryResponse = await axiosServices.get<ApiResponse<AccountSummaryDto>>('/accounts/summary');
      dispatch(slice.actions.setAccountSummary(accountSummaryResponse.data.data));

      const existingDraft = !!draftId ? await dispatch(getDraft(draftId)) : null;

      return existingDraft;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function saveDraft(saveDto: SaveSalesOrderDraftDto, suppressSnackbar?: boolean) {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const activeDraftState = getState().pinnedOrder;

      dispatch(slice.actions.setIsSavingDraft(true));
      dispatch(setFetchingPinnerOrder(true));

      const response = await axiosServices.put<ApiResponse<SalesOrderDraftDto>>('/sales-orders/drafts', saveDto);

      const success = !response.data.hasErrors;
      const draft = response.data.data;

      if (success && !suppressSnackbar) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Your draft has been saved.`,
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: true
          })
        );
      }

      if (draft.id === activeDraftState.pinnedOrder?.id) {
        dispatch(assignPinnedOrder(draft));
      }

      return draft;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setIsSavingDraft(false));
      dispatch(setFetchingPinnerOrder(false));
    }
  };
}

export function placeOrder(saveDto: SaveSalesOrderDraftDto, hold: boolean) {
  return async (dispatch: any, getState: () => RootState) => {
    const activeDraftState = getState().pinnedOrder;
    try {
      dispatch(slice.actions.setIsPlacingOrder(true));
      dispatch(slice.actions.setApiValidationErrors([]));

      const response = await axiosServices.post<ApiResponse<ValidatedResult<SalesOrderHeader>>>(
        `/sales-orders/place-order?hold=${hold}`,
        saveDto
      );

      const result = response.data.data;

      if (!result.isValid) {
        dispatch(slice.actions.setApiValidationErrors(result.validationFailures));
        return result;
      }

      dispatch(slice.actions.setApiValidationErrors([]));

      if (saveDto.existingDraftId === activeDraftState?.pinnedOrder?.id) {
        dispatch(assignPinnedOrder(null));
        clearLocalStorage(LocalStorageKeys.ActiveDraftId);
      }

      return result;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setIsPlacingOrder(false));
    }
  };
}

export function loadDeliveryOptions(formState: SalesOrderDraftDto) {
  return async (dispatch: any, getState: () => RootState) => {
    const currentState = getState();
    const state = currentState?.salesOrderCreate;

    const abortPreviousRequest: boolean = state.isFetchingDeliveryOptions;

    let abortController = state.deliveryOptionsAbortController ?? new AbortController();

    if (abortPreviousRequest) {
      abortController.abort();
      abortController = new AbortController();
    }

    if (formState.products.length === 0) {
      dispatch(slice.actions.setDeliveryOptions(null));
      dispatch(slice.actions.setIsFetchingDeliveryOptions({ isFetching: false }));
      return;
    }

    try {
      dispatch(slice.actions.setDeliveryOptions(null));
      dispatch(slice.actions.setIsFetchingDeliveryOptions({ isFetching: true, abortController: abortController }));

      const activeAccount = currentState?.accountInContext?.accountRole?.account;

      const saveDto = mapToSaveDraftDto(activeAccount!.accountCode, formState);

      const response = await axiosServices.post<ApiResponse<DeliveryOption[]>>('/sales-order-drafts/delivery-options', saveDto, {
        signal: abortController.signal
      });

      dispatch(slice.actions.setDeliveryOptions(response.data.data));

      return response.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      if (abortController.signal.aborted) return;

      dispatch(slice.actions.setIsFetchingDeliveryOptions({ isFetching: false, abortController: abortController }));
    }
  };
}

export function loadDraftTotals(formState: SalesOrderDraftDto, deliveryOptionSellPrice: number | null) {
  return async (dispatch: any, getState: () => RootState) => {
    const currentState = getState();
    const state = currentState?.salesOrderCreate;

    const abortPreviousRequest: boolean = state.isFetchingTotals;

    let abortController = state.totalsAbortController ?? new AbortController();

    if (abortPreviousRequest) {
      abortController.abort();
      abortController = new AbortController();
    }

    try {
      dispatch(slice.actions.setFetchingTotals({ isFetching: true, abortController: abortController }));
      dispatch(slice.actions.setTotals(CreateSalesOrderInitialState.totals));

      const activeAccount = currentState?.accountInContext?.accountRole?.account;

      const saveDto = mapToSaveDraftDto(activeAccount!.accountCode, formState);

      let queryUrl = '/sales-orders/drafts/totals';

      if (deliveryOptionSellPrice) {
        queryUrl += `?deliveryOptionSellPrice=${deliveryOptionSellPrice}`;
      }

      const response = await axiosServices.post<ApiResponse<SalesOrderDraftTotalsDto>>(queryUrl, saveDto, {
        signal: abortController.signal
      });

      const totals = response.data.data;

      dispatch(slice.actions.setTotals(totals));
    } catch (error) {
      console.error(error);
    } finally {
      if (abortController.signal.aborted) return;

      dispatch(slice.actions.setFetchingTotals({ isFetching: false, abortController: abortController }));
    }
  };
}

export function getDraft(draftId: number) {
  return async () => {
    try {
      const response = await axiosServices.get<ApiResponse<SalesOrderDraftDto>>(`/sales-orders/drafts/${draftId}`);

      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  };
}
